export default class routes {

    static noAgent = `
        <p>You are not logged in!</p>
    `;

    static authedPages = ["/", "/rooms", "/notifications", "/callback"];

    static main = async (agent) => {

        const content = document.getElementById("content");
    
        const route = document.location.pathname;
        const param = document.location.search;
        const hash = document.location.hash;

        var authedPage = false;

        for (const page of this.authedPages) {

            if (page == route) authedPage = true;
        }

        if (authedPage == true && agent == null) {

            content.innerHTML = this.noAgent;
            return;
        }
    
        if (route == "/") {
    
            content.innerHTML = await agent.getTimeline().then((r) => JSON.stringify(r.data));
        } else if (route == "/about") {
    
            content.innerHTML = `
                <h3>About</h3>
                <p>lightweight is a minimalistic AT Proto wrapper.</p>
                <p>Its objective is to use as little code as possible to wrap the lexicon.</p>
                <br>
                <h3>Maintainers</h3>
                <a href="https://amazingca.dev" target="_blank">@caleb.bsky.social</a>
            `;
        } else if (route == "/acknowledgments") {
    
            content.innerHTML = `
                <h3>Acknowledgments</h3>
                <p>lightweight is in a local-intended beta that uses these libraries:</p>
                <br>
                <h4>U.S. Graphics</h4>
                <a href="https://berkeleygraphics.com/typefaces/berkeley-mono" target="_blank" class="link">Berkeley Mono (local Trial)</a>
                <br>
                <br>
                <h4>Parcel Bundler</h4>
                <a href="https://github.com/parcel-bundler/parcel" target="_blank" class="link">Parcel</a>
                <br>
                <br>
                <h4>Bluesky Social</h4>
                <a href="https://github.com/bluesky-social/atproto" target="_blank" class="link">AT Protocol</a>
                <br>
                <br>
                <h3>Honorable Mentions</h3>
                <a href="https://atproto.com/community/projects" target="_blank" class="link">ATP Dev Community</a>
            `;
        } else if (route == "/callback") {

            content.innerHTML = `
                <p>Successfully logged in as @${await agent.getProfile({ actor: agent.did }).then((r) => r.data.handle)}</p>
            `;
        }
    }
}