import routes from "../static/routes.js";
import { Agent } from '@atproto/api'
import { BrowserOAuthClient, OAuthAgent } from "@atproto/oauth-client-browser";

async function main() {

    const oauthClient = await BrowserOAuthClient.load({
        clientId: "https://lw.amazingca.dev/client-metadata.json",
        handleResolver: "https://bsky.social/",
    });

    const result = await oauthClient.init();

    if (result) {

        if ('state' in result) {
            console.log("The user was just redirected back from the authorization page");
        }

        console.log(`The user is currently signed in as`, result);
    }

    const login = document.getElementById("login");

    login.addEventListener("click", async () => {

        const handle = prompt('Enter your atproto handle to authenticate');
        if (!handle) throw new Error('Authentication process canceled by the user');
      
        const url = await oauthClient.authorize(handle);
      
        // Redirect the user to the authorization page
        window.open(url, '_self', 'noopener');
      
        // Protect against browser's back-forward cache
        await new Promise<never>((resolve, reject) => {
            setTimeout(
                reject,
                10_000,
                new Error('User navigated back from the authorization page'),
            )
        });
    });

    var agent = null;
      
    if (result) {
        
        agent = new Agent(result.session);

        const fetchProfile = async () => {
            const profile = await agent.getProfile({ actor: agent.did })
            return profile.data
        }

        // Update the user interface

        while (document.getElementsByClassName("hidden").length > 0) {
            
            for (const element of document.getElementsByClassName("hidden")) {

                element.classList.remove("hidden");
            }
        }

        login.classList.add("hidden");

        const logout = document.getElementById("logout");

        logout.addEventListener("click", async () => {
            await result.session.signOut();
            window.location.reload();
        });

        document.getElementById("handle").innerHTML = await fetchProfile().then((r) => "@" + r.handle);

        document.getElementById("notifications").innerHTML += await agent.countUnreadNotifications().then((r) => " [" + r.data.count + "]");
    }

    routes.main(agent);

    document.getElementById("sidebar-padding").style.width = document.getElementById("sidebar").offsetWidth + "px";
}

document.addEventListener('DOMContentLoaded', main);